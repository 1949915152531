import React, { useEffect, useState } from 'react';
import {
  Table,
  TableProps
} from 'antd';
import axios from 'axios';

interface DataType {
  key: string;
  rating: number;
  player: string;
  score: number;
}

function getScoreColor(score: number) {
  if (score >= 5001 && score <= 7500)
    return { color: '#1ce400', background: '#084a00' };
  else if (score >= 7501 && score <= 10000)
    return { color: '#ffc800', background: '#654f00' };
  else if (score >= 10001 && score <= 12500)
    return { color: '#ff6309', background: '#652703' };
  else if (score >= 12501)
    return { color: '#fe1f00', background: '#640b00' };
  else
    return { color: '#eeeeee', background: '#545454' };
}

interface IMap {
  ID: number;
  Title: string;
  Difficulty: number;
}

interface IPlayer {
  PlayerID: number;
  FullName: string;
  Maps: IPlayerMap[];
  Score: number;
}

interface IPlayerMap {
  ID: number;
  Difficulty: number;
  Time: string;
}

interface IDataSource {
  key: string;
  rating: number;
  player: string;
  map_1: string;
  map_2: string;
  map_3: string;
  score: number;
}

function formatTime(time: string | undefined): string {
  if (time === null || time === undefined)
      return "-";

  let parts = time.split("T")[1].slice(0, time.split("T")[1].length-1).split(':');
  console.log(parts)
  return parts[1] + ':' + parts[2];
}

const HomePage: React.FC = () => {
  const [columns, setColumns] = useState<TableProps<DataType>['columns']>([]);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [prize, setPrize] = useState<number>(15000);
  const [dayPrize, setDayPrize] = useState<number>(0);

  useEffect(() => {
    async function getRating() {
      try {
        const response = await axios.post('https://vride.dewittanthe.ru/api/rating');
        let players: IPlayer[] = response.data;

        players = players.sort((p1, p2) => p1.Score < p2.Score ? 1 : -1);

        const _dataSource: IDataSource[] = [];

        players.forEach((player, index) => {
          const map1 = player.Maps.find(x => x.ID === 1);
          const map2 = player.Maps.find(x => x.ID === 2);
          const map3 = player.Maps.find(x => x.ID === 3);

          _dataSource.push({
            key: `${player.PlayerID}`,
            rating: index+1,
            player: player.FullName,
            map_1: formatTime(map1?.Time),
            map_2: formatTime(map2?.Time),
            map_3: formatTime(map3?.Time),
            score: Math.floor(player.Score * 10) / 10  
          })
          console.log(_dataSource)
        });

        setDataSource(_dataSource);
      } catch (error) {}
    }

    async function getMaps() {
      try {
        const response = await axios.post('https://vride.dewittanthe.ru/api/maps');

        let _maps: IMap[] = response.data;
        _maps = _maps.sort((p1, p2) => p1.ID > p2.ID ? 1 : -1);

        const _columns: TableProps<DataType>['columns'] = [];
        _columns.push({
          title: 'Рейтинг',
          dataIndex: 'rating',
          key: 'rating',
          align: 'center',
          width: 150
        });
        _columns.push({
          title: 'Игрок',
          dataIndex: 'player',
          key: 'player',
          width: 200
        });
        _maps.forEach(map => {
          _columns.push({
            title: map.Title,
            dataIndex: `map_${map.ID}`,
            key: `map_${map.ID}`,
            align: 'center'
          });
        });
        _columns.push({
          title: 'Очки',
          dataIndex: 'score',
          key: 'score',
          width: 150,
          render: (score: number) => {
            const colors = getScoreColor(score);
            return (
              <div className='score' style={{ background: colors.background }}>
                <div className='line' style={{ background: colors.color }} />
                <div className='line' style={{ background: colors.color, marginLeft: '.125rem' }} />
                <div className='value' style={{ color: colors.color, textWrap: 'nowrap' }}>{score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',')}</div>
              </div>
            )
          }
        });
        
        setColumns(_columns);
        getRating();
      } catch (error) {}
    }

    async function getPrize() {
      try {
        const response = await axios.post('https://vride.dewittanthe.ru/api/prize');
        setPrize(response.data);
      } catch (error) {}
    }

    async function getDayPrize() {
      try {
        const response = await axios.post('https://vride.dewittanthe.ru/api/day-prize');
        setDayPrize(response.data);
      } catch (error) {}
    }

    getMaps();
    getPrize();
    getDayPrize();
  }, [
    setColumns,
    setDataSource,
    setPrize
  ]);

  return (
    <>
      <div className="block">
      <div className='info'>
        <div className='description'>
          <h3>сквозной турнир по игре assetto corsa</h3>
          <p>Подробности можно узнать у <span style={{ color: 'red' }}>Администратора</span>.<br />Или по телефону: <b>+7 (3953) 200-175</b></p>
        </div>
        <div className='flag'></div>
        <div className='prize_fund'>
          <div className="description">
            <h3>текущий призовой фонд</h3>
            <p><span style={{ color: 'red' }}>5%</span> от стоимости игры, участвующей в турнире,<br></br> идут в призовой фонд.</p>
          </div>
          <div className='divider' />
          <div className="bank">
            <ul style={{ listStyle: 'none' }}>
              <li className='prize'>{prize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽</li>
              {
                dayPrize > 0
                ? <li style={{ fontSize: '16px', textAlign: 'right', color: '#00FF00', lineHeight: '0' }}>+{dayPrize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽</li>
                : <></>
              }
            </ul>
          </div>
        </div>
      </div>
      </div>
      <Table<DataType>
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: 140 * 5 }}
        style={{
          width: '85%',
          height: '100%',
          margin: '0 auto',
          marginTop: '12px',
          top: '200px'
        }}
      />
    </>
  );
}

export default HomePage;