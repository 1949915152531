import { Table, TableProps } from 'antd';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

type FieldType = {
    Time: string;
    PlayerID: number;
    MapID: number;
    Prize: number;
};

interface Game {
    ID: number;
    Time: string;
    PlayerID: number;
    MapID: number;
    Prize: number;
}

interface DataType {
    key: string;
    id: number;
    time: string;
    playerId: number;
    mapId: number;
    prize: number;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center'
    },
    {
        title: 'Время',
        dataIndex: 'time',
        key: 'time',
        align: 'center'
    },
    {
        title: 'ID игрока',
        dataIndex: 'playerId',
        key: 'playerId',
        align: 'center'
    },
    {
        title: 'ID карты',
        dataIndex: 'mapId',
        key: 'mapId',
        align: 'center'
    },
    {
        title: 'В банк',
        dataIndex: 'prize',
        key: 'prize',
        align: 'center'
    }
];

function formatTime(time: string | undefined): string {
    if (time === null || time === undefined)
        return "-";

    let parts = time.split("T")[1].slice(0, time.split("T")[1].length-1).split(':');

    return parts[1] + ':' + parts[2];
}

const GamesPage: React.FC = () => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);

    useEffect(() => {
        async function fetchGames() {
            try {
                const response = await axios.post('https://vride.dewittanthe.ru/api/get-games', {}, {withCredentials: true});
                console.log(response.data)
                let games: Game[] = response.data;        
                const _dataSource: DataType[] = [];
        
                games.forEach((game) => {
                    _dataSource.push({
                        key: `${game.ID}`,
                        id: game.ID,
                        time: formatTime(game.Time),
                        playerId: game.PlayerID,
                        mapId: game.MapID,
                        prize: game.Prize
                    });
                });
        
                setDataSource(_dataSource);
            } catch(e) {}
        }

        fetchGames();
    }, [setDataSource]);

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const response = await axios.post('https://vride.dewittanthe.ru/api/add-game', {
                Time: values.Time,
                PlayerID: Number(values.PlayerID),
                MapID: Number(values.MapID),
                Prize: Number(values.Prize)
            }, {withCredentials: true});
            if (response.status === 200) {
                setDataSource(oldDataSource => {
                    const newDataSource = [ ...oldDataSource ];
                    newDataSource.push({
                        key: `${response.data.ID}`,
                        id: response.data.ID,
                        time: "00:" + values.Time,
                        playerId: values.PlayerID,
                        mapId: values.MapID,
                        prize: values.Prize
                    });
                    return newDataSource;
                });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 400)
                    alert('Произошла ошибка');
            }
            console.log(error)
        }
    };
    
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
        <Link to={'/'} >Рейтинг</Link><br/>
        <Link to={'/players'} >Участники</Link>
            <Form
                name="basic"
                style={{ maxWidth: 400, background: '#1d1d1d', padding: '1rem', borderRadius: '.75rem', margin: '16px auto' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Время (00:00.000)"
                    name="Time"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="ID игрока"
                    name="PlayerID"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="ID карты"
                    name="MapID"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="В банк"
                    name="Prize"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item  style={{ padding: '0', margin: '0' }}>
                    <Button type="primary" htmlType="submit" block>
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
            <Table<DataType>
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
        </>
    );
}

export default GamesPage;