import { Table, TableProps } from 'antd';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

type FieldType = {
    PhoneNumber: string;
    FullName: string;
};

interface Player {
    ID: number;
    FullName: string;
    PhoneNumber: string;
}

interface DataType {
    key: string;
    id: number;
    fullName: string;
    phoneNumber: string;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center'
    },
    {
        title: 'Имя',
        dataIndex: 'fullName',
        key: 'fullName',
        align: 'center'
    },
    {
        title: 'Номер телефона',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        align: 'center'
    }
];

const PlayersPage: React.FC = () => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);

    useEffect(() => {
        async function fetchPlayers() {
            

            try {
                const response = await axios.post('https://vride.dewittanthe.ru/api/get-players', {}, {withCredentials: true});
                let players: Player[] = response.data;        
                const _dataSource: DataType[] = [];
        
                players.forEach((player, index) => {
                    _dataSource.push({
                        key: `${player.ID}`,
                        id: player.ID,
                        fullName: player.FullName,
                        phoneNumber: player.PhoneNumber
                    })
                });
        
                setDataSource(_dataSource);
            } catch(e) {}
        }

        fetchPlayers();
    }, [setDataSource]);

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const response = await axios.post('https://vride.dewittanthe.ru/api/add-player', {
                PhoneNumber: values.PhoneNumber,
                FullName: values.FullName
            }, {withCredentials: true});
        
            if (response.status === 200) {
                setDataSource(oldDataSource => {
                    const newDataSource = [ ...oldDataSource ];
                    newDataSource.push({
                        key: `${response.data.ID}`,
                        id: response.data.ID,
                        fullName: values.FullName,
                        phoneNumber: values.PhoneNumber
                    });
                    return newDataSource;
                });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 400)
                    alert('Произошла ошибка');
            }
            console.log(error)
        }
    };
    
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
        <Link to={'/'} >Рейтинг</Link><br/>
        <Link to={'/games'} >Игры</Link>
            <Form
                name="basic"
                style={{ maxWidth: 400, background: '#1d1d1d', padding: '1rem', borderRadius: '.75rem', margin: '16px auto' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Имя"
                    name="FullName"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Номер телефона"
                    name="PhoneNumber"
                    rules={[{ required: true, message: 'Это обязательное поле!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item  style={{ padding: '0', margin: '0' }}>
                    <Button type="primary" htmlType="submit" block>
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
            <Table<DataType>
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
        </>
    );
}

export default PlayersPage;