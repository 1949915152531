import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import ScoReflect from './ScoReflect';

import './assets/scss/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScoReflect />
    </BrowserRouter>
  </React.StrictMode>
);
