import React from 'react';
import {
  Route,
  Routes
} from 'react-router-dom';
import {
  App,
  ConfigProvider,
  theme
} from 'antd';

import ru_RU from 'antd/locale/ru_RU';

import Layout from './components/Layout';
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import PlayersPage from './pages/Players';
import GamesPage from './pages/Games';

const ScoReflect: React.FC = () => {
  return (
    <ConfigProvider
      locale={ru_RU}
      theme={{
        algorithm: theme.darkAlgorithm,
      }}>
      <App>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/signin' element={<SignInPage />} />
            <Route path='/players' element={<PlayersPage />} />
            <Route path='/games' element={<GamesPage />} />
          </Route>
        </Routes>
      </App>
    </ConfigProvider>
  );
}

export default ScoReflect;