import React from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

type FieldType = {
  PhoneNumber?: string;
  Password?: string;
};

const SignInPage: React.FC = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      const response = await axios.post('https://vride.dewittanthe.ru/api/auth/sign-in', {
        PhoneNumber: values.PhoneNumber,
        Password: values.Password
      }, {withCredentials: true});
    
      if (response.status === 200 && response.data.Success) {
        console.log('Success');
        navigate(`/players`, { replace: true });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400)
          alert('Неверный логин или пароль');
      }
    }
  };
  
  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Form
        name="basic"
        style={{ maxWidth: 400, background: '#1d1d1d', padding: '1rem', borderRadius: '.75rem' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Номер телефона"
          name="PhoneNumber"
          rules={[{ required: true, message: 'Это обязательное поле!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Пароль"
          name="Password"
          rules={[{ required: true, message: 'Это обязательное поле!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item  style={{ padding: '0', margin: '0' }}>
          <Button type="primary" htmlType="submit" block>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
    </>
  );
}

export default SignInPage;